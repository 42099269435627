import axios from "axios";
const Papa = require("papaparse");
const fs = require("fs");
import i18n from "../../i18n";
import * as fb from "@/firebase/index.js";
import { floor } from "@tensorflow/tfjs-core";
// import { time } from "console";
export default {
  state: {
    hearingInfo: [],
    lastFetched: null,
    intervalTimer: 300000,
    judgeCourtRoomDetails: {
      wheatcraft: "Courtroom 1",
      ryan: "Courtroom 3",
      binder: "Courtroom 4",
      hall: "Courtroom 5",
      black: "Courtroom 6",
      royer: "Courtroom 7",
      maddox: "Courtroom 8",
      carmody: "Courtroom 10",
      forzato: "Courtroom 11",
      mccabe: "Courtroom 12",
      sondergaard: "Courtroom 14",
      verwey: "Courtroom 2",
      rovito: "Courtroom 16",
      sommer: "Courtroom 15",
      platt: "Courtroom 15",
      cody: "Courtroom 15",
      bortner: "Courtroom 15",
    },
  },
  getters: {
    hearingInfo: (state) => state.hearingInfo,
    lastFetched: (state) => state.lastFetched,
    // getHearingLocation: (state) => state.hearingLocation,
    intervalTimer: (state) => state.intervalTimer,
  },
  mutations: {
    setHearingInfo(state, hearingObj) {
      //console.log('Hearing Obj', hearingObj)
      //state.hearingInfo.push(hearingObj)
      state.hearingInfo = hearingObj;
    },
    emptyHearingInfo(state) {
      state.hearingInfo = [];
    },
    setLastFetched(state, fetchObj) {
      state.lastFetched = fetchObj;
    },
    setIntervalTimer(state, timer) {
      state.intervalTimer = timer;
    },
  },
  actions: {
    verifyFile({ dispatch, getters, state, commit }, targetDateIn) {
      console.log("Target Date:", targetDateIn);

      return new Promise((resolve, reject) => {
        var manualId = 0;
        //Fetch all files with todays date in format MM.DD.YY
        let filesOnTargetDate = [];
        let data_file = JSON.stringify({
          userName: "chester-pa",
          apiKey:
            "ars-bb-E4uOcpcLgEIKXd-pY5x9Z-bo!7Wl-mLuQCRTmvhqzi4kYpizFdV!tdJizI?Yb75HVSlpw74t-IodAkfSW3qNk7e2OZ8Vwa-ffojXZ2oWrP-AmV0y1z3?SJKmL",
        });

        const config_files = {
          method: "post",
          url: "https://us-central1-chester-pa-70c3b.cloudfunctions.net/apiV2/getHearingFiles/a763cd056f1b2405788443b7197e0708",
          headers: {
            "Content-Type": "application/json",
          },
          data: data_file,
        };

        const mapPath = i18n
          .t("maps")
          .filter((map) => map.hearingLocationCode !== null);

        console.log(mapPath, "these are maps");

        // Preprocess the floors array into a lookup object
        const floorLookup = i18n.t("floors").reduce((lookup, floor) => {
          lookup[floor.floorNumber] = floor.floorName;
          return lookup;
        }, {});

        // const floors = i18n.t("floors");
        // console.log(floors, "this are floors");

        const floorNumber = mapPath.reduce((acc, map) => {
          if (map.mapFloor in floorLookup) {
            acc[map.mapName] = floorLookup[map.mapFloor];
          }
          return acc;
        }, {});

        // console.log(floorNumber, "Map i need for floors");

        // Fetch files using Axios
        axios(config_files)
          .then((response) => {
            let temp = [];
            console.log(response, "getting this from API");
            const data = response.data;

            // Extract files with modifiedTime on "2024-12-12"
            // const todaysDate = new Date().toISOString().split("T")[0];

            // console.log("Today's Date:",todaysDate);
            const customdate = '1.24.25'

            // commit("setLastFetched", response.data.Header.Time);
            const today = new Date();
            const todayFormatted = `${(today.getMonth() + 1)
              .toString()
              .padStart(2, "0")}.${today
              .getDate()
              .toString()
              .padStart(2, "0")}.${today.getFullYear().toString().slice(-2)}`;
            // console.log(todayFormatted, "This is the format for date.");

            const todayFormatted2 = `${
              today.getMonth() + 1
            }.${today.getDate()}.${today.getFullYear().toString().slice(-2)}`;

            // console.log(todayFormatted2, "This is the format for M.D.YY.");

            // console.log(targetDateIn, "this is the targetdtae now");

            // filesOnTargetDate = data.fileDetails.filter((file) => {
            //   const fileDate = new Date(file.modifiedTime)
            //     .toISOString()
            //     .split("T")[0];
            //   return fileDate === targetDateIn;
            // });

            filesOnTargetDate = data.fileDetails.filter((file) => {
              // const fileDate = new Date(file.modifiedTime).toISOString().split("T")[0];
              // const nameDateMatch = file.name.match(/\d{2}\.\d{2}\.\d{2}/);
              const nameDateMatch = file.name.match(/\d{1,2}\.\d{1,2}\.\d{2}/);
              const fileNameDate = nameDateMatch ? nameDateMatch[0] : null;

              // Check both conditions
              return (
                fileNameDate === todayFormatted ||
                fileNameDate === todayFormatted2 ||
                fileNameDate === customdate
              );
            });

            console.log("Filtered Files:", filesOnTargetDate);

            if (filesOnTargetDate && filesOnTargetDate.length > 0) {
              filesOnTargetDate.forEach((file) => {
                // console.log(file.name, "This is file Name");

                let data = JSON.stringify({
                  userName: "chester-pa",
                  filePath: file.name,
                  apiKey:
                    "ars-bb-E4uOcpcLgEIKXd-pY5x9Z-bo!7Wl-mLuQCRTmvhqzi4kYpizFdV!tdJizI?Yb75HVSlpw74t-IodAkfSW3qNk7e2OZ8Vwa-ffojXZ2oWrP-AmV0y1z3?SJKmL",
                });
                // console.log(data, "this is the ftp data");

                const config = {
                  method: "post",
                  url: "https://us-central1-chester-pa-70c3b.cloudfunctions.net/apiV2/getHearingDetails/a763cd056f1b2405788443b7197e0708",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: data,
                };

                axios(config)
                  .then((response) => {
                    let hearingObj;

                    // console.log(response.data,": This is the data i want to look");

                    const parsedData = Papa.parse(response.data, {
                      header: false, // Set to `true` if the data includes headers
                      skipEmptyLines: true,
                    });

                    const rows = parsedData.data;

                    // const filenamePrefix = file.name.split(/[^a-zA-Z]/)[0].toLowerCase();

                    if (file.name.split(" ")[0].toLowerCase() === "cpcms") {
                      console.log(file.name, "Processed for CPCMS");
                      rows.forEach((element) => {
                        hearingObj = {
                          id: manualId++,
                          hearingLocation: element[22],
                          caseNumber: element[23],
                          hearingTime: element[27],
                          partyName: element[24].toLowerCase().startsWith("comm") ? element[24].substring(8) : element[24],
                          floorName: floorNumber[element[22]],
                        };

                        if (
                          hearingObj.caseNumber !== "" ||
                          hearingObj.partyName !== ""
                        ) {
                          console.log("Inside here?");
                          const exists = temp.some(
                            (item) =>
                              item.caseNumber === hearingObj.caseNumber &&
                              item.partyName === hearingObj.partyName
                          );
                          if (!exists) {
                            temp.push(hearingObj);
                          }
                        } else {
                          console.log(
                            hearingObj,
                            "This object didn't pushed because of no case number or partyName"
                          );
                        }
                      });
                    } else if (file.name.split(" ")[0].toLowerCase() === "cv") {
                      console.log(file.name, "Processed for CV");
                      // Hall does not have name

                      // console.log(floorNumber, "This are floor Numbers");
                      rows.forEach((element) => {
                        hearingObj = {
                          id: manualId++,
                          hearingLocation:
                            state.judgeCourtRoomDetails[
                              file.name.split(" ")[1].toLowerCase()
                            ],
                          caseNumber: element[14],
                          hearingTime: element[12],
                          partyName: element[18],
                          floorName:
                            floorNumber[
                              state.judgeCourtRoomDetails[
                                file.name.split(" ")[1].toLowerCase()
                              ]
                            ],
                        };
                        
                        // console.log(hearingObj, "This is hearing OBJ");
                        if (
                          hearingObj.caseNumber !== "" ||
                          hearingObj.partyName !== ""
                        ) {
                          console.log("Inside here?");
                          const exists = temp.some(
                            (item) =>
                              item.caseNumber === hearingObj.caseNumber &&
                              item.partyName === hearingObj.partyName
                          );
                          if (!exists) {
                            temp.push(hearingObj);
                          }
                        } else {
                          console.log(
                            hearingObj,
                            "This object didn't pushed because of no case number or partyName"
                          );
                        }
                      });
                    } else if (
                      file.name.split(" ")[0].toLowerCase() === "misc"
                    ) {
                      console.log(file.name, "Processed for Misc");
                      // Hall does not have name
                      rows.forEach((element) => {
                        hearingObj = {
                          id: manualId++,
                          hearingLocation:
                            element[5] || element[3].split("")[2].toLowerCase(),
                          caseNumber: element[8],
                          hearingTime: element[6],
                          partyName: element[12],
                          floorName:
                            floorNumber[element[5]] ||
                            floorNumber[element[3].split("")[2].toLowerCase()],
                        };
                       
                        if (
                          hearingObj.caseNumber !== "" ||
                          hearingObj.partyName !== ""
                        ) {
                          console.log("Inside here?");
                          const exists = temp.some(
                            (item) =>
                              item.caseNumber === hearingObj.caseNumber &&
                              item.partyName === hearingObj.partyName
                          );
                          if (!exists) {
                            temp.push(hearingObj);
                          }
                        } else {
                          console.log(
                            hearingObj,
                            "This object didn't pushed because of no case number or partyName"
                          );
                        }
                      });
                    }
                    else if(file.name.split(" ")[0].toLowerCase() === "icc"){
                      console.log(file.name, "Processed for ICC");

                      
                      // Hall does not have name
                      rows.forEach((element) => {
                        const Timein = element[32].match(/\b\d{1,2}:\d{2} (am|pm)\b/i)
                        hearingObj = {
                          id: manualId++,
                          hearingLocation:
                            element[29] || state.judgeCourtRoomDetails[file.name.split(" ")[1].toLowerCase()],
                          caseNumber: element[30],
                          hearingTime: Timein,
                          partyName : element[31].toLowerCase().startsWith("comm") ? element[31].substring(8) : element[31],
                          floorName:
                            floorNumber[element[29]] ||
                            floorNumber[state.judgeCourtRoomDetails[file.name.split(" ")[1].toLowerCase()]],
                        };
                       
                        if (
                          hearingObj.caseNumber !== "" ||
                          hearingObj.partyName !== ""
                        ) {
                          const exists = temp.some(
                            (item) =>
                              item.caseNumber === hearingObj.caseNumber &&
                              item.partyName === hearingObj.partyName
                          );
                          if (!exists) {
                            temp.push(hearingObj);
                          }
                        } else {
                          console.log(
                            hearingObj,
                            "This object didn't pushed because of no case number or partyName"
                          );
                        }
                      });
                    }
                    
                    else {
                      console.log(
                        "File is not in the given format : ",
                        file.name
                      );
                      const badFileData = {
                        filename : file.name
                      }
                      let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://cms.chester.arsconnect.com/badftpfiles',
                        headers: {
                            // 'Content-Type': 'application/json',
                            // Authorization: `Bearer ${jwtToken}`,
                        },
                        data: badFileData
                    };
                    axios.request(config)
                        .then((response) => {
                            //console.log(JSON.stringify(response.data));
                            console.log("BAD FTP File Added");
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    }
                    // console.log(temp.length, "This is the length");
                    // console.log(temp,"Object for temp");
                    // temp.sort((a, b) => new Date(b.hearingTime) - new Date(a.hearingTime));
                    commit("setHearingInfo", temp);

                    // fb.kioskCollection.get().then((snapshot) => {
                    //   snapshot.docs.forEach((doc) => {
                    //     // console.log("Data for Each Prod Kiosk:", doc.data());
                    //     // console.log(this.getters.getKioskProfile,"this is the kioskprofile");
                    //     if (doc.id === this.getters.getKioskProfile.id) {
                    //       const modules = doc.data().modules;
                    //       if (modules.includes("hearing")) {
                    //         // console.log(doc.data().name,"Kiosk with hearing module right now");
                    //         fb.kioskCollection.doc(doc.id).update({
                    //           pullFiles: false,
                    //         });
                    //       }
                    //     }
                    //   });
                    // });

                    fb.kioskCollection
                      .doc(getters.getKioskId)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          // const data = doc.data()
                          const modules = doc.data().modules;
                          if (modules.includes("hearing")) {
                            console.log(doc.id, "Updating Pull Files to false");
                            fb.kioskCollection.doc(doc.id).update({
                              pullFiles: false,
                            });
                          }
                        }
                      });

                    resolve(true);
                  })
                  .catch((error) => {
                    resolve(false);
                    if (error.response.status === 404) {
                      console.log("File not present on FTP server");
                    } else if (error.response.status === 403) {
                      console.log("Access problem to FTP Server");
                    }
                  });
              });
            }
            else{
              console.log("No files to upload today.");
              resolve(true)
            }
          })
          .catch((error) => {
            console.error("Error fetching files:", error.message);
          });
      });
    },

    listenToPullFilesChange({ dispatch }) {
      // fb.kioskCollection.onSnapshot((snapshot) => {
      // snapshot.docChanges().forEach((change) => {
      // if (change.type === "modified") {
      // console.log(change.doc.id,"This is ID")
      // console.log(change.doc.data(),"This is changeddata");
      // if (change.doc.data().pullFiles === true) {
      const today = new Date();
      // Take Friday if Sunday else one day before the day.
      today.setDate(today.getDate() - (today.getDay() === 1 ? 3 : 1));
      const datePullFiles = today.toISOString().split("T")[0];
      dispatch("verifyFile", datePullFiles).then((response) => {
        if (response) {
          this.loading = false;
        } else {
          this.$store.dispatch(
            "avatarSpeak",
            "Error fetching hearing data. Please go to the Court Administration Office on 4th floor. "
          );
        }
      });
      // }
      // }
      // });
      // });
    },

    filePullScheduler({ dispatch, getters }) {
      // const currentDate = new Date();
      // const hour = currentDate.getHours();
      // // 7 AM to 6 PM
      // // if (hour === 11) {
      // //     location.reload()
      // // }
      // if (hour >= 7 && hour < 17) {
      //   dispatch("verifyFile");
      //   setTimeout(() => {
      //     dispatch("filePullScheduler");
      //   }, 300000);
      // } else if (hour >= 18) {
      //   dispatch("verifyFile");
      //   setTimeout(() => {
      //     dispatch("filePullScheduler");
      //   }, 3600000);
      // } else {
      //   dispatch("verifyFile");
      //   setTimeout(() => {
      //     dispatch("filePullScheduler");
      //   }, 3600000);
      // }
    },
  },
};
