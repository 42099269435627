<template>
  <v-row no-gutters id="avatar-wrapper" :style="{ height: height + 'px', width: width + 'px' }">
    <v-col align="center" justify="center" id="divVHSS" cols="12">
    </v-col>
    <div id="avatar-accessories">
      <div style="width: 600px; margin-left: 215px;" v-if="width > 600">
        <v-row no-gutters>
          <v-col cols="1" @click="initiateLogout('1')">
            <v-btn height="100" class="d-none">Close</v-btn>
          </v-col>
          <v-col cols="10">
            <v-card style="border-radius: 30px !important" class="d-flex justify-center py-4"
              color="rgb(255, 255, 255, 0.7)">
              <img width="450" src="@/assets/logo.png">
            </v-card>
          </v-col>
          <v-col cols="1" @click="initiateLogout('2')">
            <v-btn height="100" class="d-none">Close</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "avatar",
  props: {
    initialWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      width: this.initialWidth,
      count: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentLanguage",
      "getAvatarSpeech",
      "getIsSpeaking",
      "getDefaultBubbleText",
      "getenableADA",
      "getAvatarLangCodes",
      "getAvatarID",
      "getAfterHours",
      "getAvatarLoaded"
    ]),
    height: {
      get() {
        if (this.$route.path === "/" || this.$route.path === "/loadData") {
          // return this.width * 1.125 - 180
          return this.width - 65;
        }
        return this.width - 15;
      },
      set(newVal) {
        this.width = newVal;
      },
    },
    spVoice: function () {
      return this.getAvatarLangCodes.voiceId;
    },
    spLang: function () {
      return this.getAvatarLangCodes.langId;
    },
    spEngine: function () {
      return this.getAvatarLangCodes.engineId;
    },
  },
  watch: {
    width: function () {
      if (this.$route.path === "/" || this.$route.path === "/loadData") {
        window.dynamicResize(this.width, this.height);
      } else {
        window.dynamicResize(this.width, this.height);
      }
    },
    getAvatarSpeech(newVal) {
      console.log("At avatar speech watch: ", newVal);
      this.speak(newVal);
    },
    getAvatarLoaded(newVal) {
      console.log('New Value at :', newVal)
      if (!newVal) {
        console.log('Coming in here? ')
        this.width = 0
      }
    }
  },
  methods: {
    speak: function (speakText) {
      if (this.getIsSpeaking) window.stopSpeech();
      if (this.getCurrentLanguage === "en") {
        console.log(
          "Inside speak avatar: ",
          speakText,
          this.spVoice,
          this.spLang,
          this.spEngine
        );
        window
          .sayText(speakText, this.spVoice, this.spLang, this.spEngine)
          .then((response) => {
            console.log("Response after calling speak: ", response);
          });
      } else {
        window.sayText(speakText, this.spVoice, this.spLang, this.spEngine);
      }
    },
    forceResize: function () {
      window.dynamicResize(this.width, this.height);
    },
    initiateLogout(value) {
      if (this.count === 0) {
        console.log("Set Timeout Called: ");
        setTimeout(() => {
          this.count = 0;
        }, 40000);
      }
      if (value === "1") {
        this.count = 1;
      } else {
        if (this.count >= 1) {
          console.log("Initiate Logout Count: ", this.count);
          this.count = this.count + 1;
          if (this.count === 7) {
            // call logout
            this.count = 0;
            this.$store.dispatch("logout");
          }
        }
      }
    },
  },
  mounted() {
    (window.vh_sceneLoaded = () => {
      this.forceResize();
      this.$store.commit("setAvatarLoaded", true);
      window.setStatus(1, 0, 0, 0, 0);
    }),
      (window.vh_audioStarted = () => {
        this.$store.commit("setIsSpeaking", true);
        this.$store.commit("setIsThinking", false);
      }),
      (window.vh_talkEnded = () => {
        // Has to removed once all languages are added
        if (this.$i18n.locale === "en") {
          this.$store.commit("setCurrentLanguage", "en");
        }
        this.$store.commit("setIsSpeaking", false);
        // if (!this.getDefaultBubbleText) {
        //   this.$store.commit(
        //     "setTextBubbleText",
        //     "Tap the microphone button, to ask me a question."
        //   );
        // }
      });
  },
};
</script>

<style>
#divVHSS {
  width: 100%;
  z-index: 0 !important;
  animation: none !important;
  transform: translateY(0) !important;
}

#divVHSS-inner {
  left: -10px !important;
  bottom: 0px !important;
}

.character,
.vhss_main_container,
._html5Player {
  /* height: 1085px !important; */
  margin-top: -10px !important;
  z-index: -1 !important;
  position: initial !important;
}

#avatar-accessories {
  z-index: 1;
  height: 83%;
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
}
</style>

<i18n>
{
  "en": {
      "buubletextafteraction": "Please face towards the camera, tap on the microphone button, and ask a court or county related question."
    },
  "es":{
      "buubletextafteraction": "El reconocimiento de voz está desactivado para el idioma español."
    }
}
</i18n>
