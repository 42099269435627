<template>
  <v-app>
    <v-main>
      <v-layout column v-if="!getenableADA">
        <appHeader v-if="getKioskId !== undefined"></appHeader>
        <v-fade-transition leave-absolute>
          <router-view />
        </v-fade-transition>
      </v-layout>
      <v-layout column fill-height v-else>
        <v-fade-transition leave-absolute>
          <router-view />
        </v-fade-transition>
        <appHeader></appHeader>
      </v-layout>
    </v-main>
    <pdfViewer></pdfViewer>
    <image-viewer></image-viewer>
    <email-viewer></email-viewer>
    <options-viewer></options-viewer>
    <court-timing-viewer></court-timing-viewer>
    <session-end-viewer></session-end-viewer>
    <department-service-viewer></department-service-viewer>
    <telepresence-viewer></telepresence-viewer>
    <payment-option-viewer></payment-option-viewer>
    <help-viewer></help-viewer>
    <request-form-viewer></request-form-viewer>
    <recognition></recognition>
  </v-app>
</template>

<script>
import appHeader from "@/components/appHeader.vue";
import pdfViewer from "@/components/dialogs/pdfViewer.vue";
import imageViewer from "@/components/dialogs/imageViewer";
import emailViewer from "@/components/dialogs/emailViewer";
import optionsViewer from "@/components/dialogs/optionsViewer";
import courtTimingViewer from "@/components/dialogs/courtTimingViewer";
import SessionEndViewer from "./components/dialogs/sessionEndViewer.vue";
import recognition from "@/components/recognition.vue";
import { mapGetters } from "vuex";
import DepartmentServiceViewer from "./components/dialogs/departmentServiceViewer.vue";
import TelepresenceViewer from "./components/dialogs/telepresenceViewer.vue";
import paymentOptionViewer from "./components/dialogs/paymentOptionViewer.vue";
import helpViewer from "./components/dialogs/helpViewer.vue";
import requestFormViewer from "./components/dialogs/requestFormViewer.vue";
export default {
  name: "App",
  data() {
    return {
      count: 0,
    };
  },
  components: {
    appHeader,
    pdfViewer,
    recognition,
    imageViewer,
    emailViewer,
    optionsViewer,
    courtTimingViewer,
    SessionEndViewer,
    DepartmentServiceViewer,
    TelepresenceViewer,
    paymentOptionViewer,
    helpViewer,
    requestFormViewer,
  },
  computed: {
    ...mapGetters([
      "appDimensions",
      "getKioskId",
      "getStartUserSession",
      "getTouchCount",
      "isUserLooking",
      "getenableADA",
      "getNetworkConnection",
      "getAvatarName",
      "getAfterHours"
    ]),
  },
  created() {
    // window.onclick = () => {
    //   if (this.getTouchCount === 1 && !this.isUserLooking) {
    //     this.$store.dispatch("resetKiosk");
    //   } else if (this.getTouchCount !== 0) {
    //     this.$store.commit("setTouchCount", this.getTouchCount + 1);
    //   }
    //   if (this.getTouchCount > 1 && !this.isUserLooking) {
    //     this.$store.dispatch("clearResetTimeout").then(() => {
    //       this.$store.dispatch("resetKiosk");
    //     });
    //   }
    // };
    window.onclick = () => {
      if (this.getTouchCount >= 1 && !this.getAfterHours) {
        clearTimeout(this.timeoutHours)
        this.timeoutHours = setTimeout(() => { this.$store.dispatch("kioskStartOver") }, 30000)
      }
      if (this.getTouchCount === 1 && !this.isUserLooking && this.getAfterHours) {
        console.log("in App.vue 1");
        this.$store.dispatch("resetKiosk");
      } else if (this.getTouchCount !== 0) {
        console.log("in App.vue 2");
        this.$store.commit("setTouchCount", this.getTouchCount + 1);
      }
      if (this.getTouchCount > 1 && !this.isUserLooking && this.getAfterHours) {
        console.log("in App.vue 3");
        this.$store.dispatch("clearResetTimeout").then(() => {
          this.$store.dispatch("resetKiosk");
        });
      }
    };

  },
  mounted() {
    // setInterval(() => {
    //   //console.log('Network Connection: ', navigator.onLine)
    //   if (navigator.onLine) {
    //     console.log("Kiosk is online");
    //     if (!this.getNetworkConnection) {
    //       this.$store.commit('setTextBubbleText', "Hi, my name is " + this.getAvatarName + ", I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’")
    //     }
    //     this.$store.commit('setNetworkConnection', true)
    //   } else {
    //     console.log("Kiosk went offline");
    //     this.$store.commit('setNetworkConnection', false)
    //     this.$store.commit('setTextBubbleText', "No internet connectivity. Please use the touch interface.")
    //   }
    // }, 3600000)

    // Initial check
    console.log(
      "Initially " + (window.navigator.onLine ? "on" : "off") + "line"
    );
    this.updateNetworkStatus(window.navigator.onLine);

    // Listen for online/offline events
    window.addEventListener("online", () => {
      console.log("Became online");
      this.updateNetworkStatus(true);
    });

    window.addEventListener("offline", () => {
      console.log("Became offline");
      this.updateNetworkStatus(false);
    });
  },
  methods: {
  updateNetworkStatus(isOnline) {
    if (isOnline) {
      console.log("Kiosk is online");
      if (!this.getNetworkConnection) {
        // this.$store.commit('setTextBubbleText', 
        //   "Hi, my name is " + this.getAvatarName + 
        //   ", I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’"
        // );
        this.$store.commit('setTextBubbleText', 
          this.$i18n.t('welcomeHeaderpart1') + this.getAvatarName + 
          this.$i18n.t('welcomeHeaderpart2')
        );
      }
      this.$store.commit('setNetworkConnection', true);
    } else {
      console.log("Kiosk went offline");
      this.$store.commit('setNetworkConnection', false);
      this.$store.commit('setTextBubbleText', 
        this.$i18n.t('noInternetMsg')
      );
    }
  }
}
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

/* d37444 - changed this color to 9F2B00 */
.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}

.v-list::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.v-list::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}

.v-virtual-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444 !important;
  width: 10px;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444 !important;
}

.v-dialog {
  margin-top: 100px;
}
</style>

<i18n>
{
  "en": {
      "welcomeHeaderpart1" : "Hi, my name is ",
      "welcomeHeaderpart2": ", I’m here to help you. Tap the microphone button, and ask me ‘What can you do?’",
      "noInternetMsg": "No internet connectivity. Please use the touch interface."
      
      
    },
  "es":{
      "welcomeHeaderpart1" : "Hola, me llamo ",
      "welcomeHeaderpart2": ", Estoy aquí para ayudarte. Toca el botón del micrófono y pregúntame: «¿Qué puedes hacer?",
      "noInternetMsg": "No hay conexión a Internet. Utilice la interfaz táctil."

      
    }
}
</i18n>
